<template>
  <Layout>
    <div class="base-width">
      <div class="c-card md-auth md-auth-1">
        <div class="title s26 color-2 font-medium">供应链金融企业身份认证</div>

        <form class="form s16 color-2" @submit.prevent="handleSubmit">

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">联系人姓名</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入联系人姓名" v-model="form.username">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">企业名称</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入企业名称" v-model="form.company_name">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">费率</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入范围值‰月" v-model="form.rate">
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">主营业务</p>
                <p class="color-6 s14 p_tips">(可多选)</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseCheckbox :options='busiArr' v-model="business" name='business' @change="busiChange" :key="busiKey"></BaseCheckbox>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">服务范围</p>
                <p class="color-6 s14 p_tips">(可多选)</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseCheckbox :options='serviceArr' v-model="service" name='service' @change="serviceChange" :key="serviceKey"></BaseCheckbox>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">垫付期限</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseRadio :options='periodArr' v-model="form.period" name='time'></BaseRadio>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label required">
              <div>
                <p class="p_label">服务区域</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseRadio :options='areaArr' v-model="form.area_scope" name='area'></BaseRadio>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">营业执照</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseFile v-model="licenseArr" placeholder="请上传营业执照" :max='1' @change="licenseChange" :key="licenKey">
              </BaseFile>
            </div>
          </div>

          <div class="form-item form-item--idcard">
            <label class="form-item_label required">
              <div>
                <p class="p_label">身份证图片</p>
              </div>
            </label>
            <div class="form-item_content">
              <BaseIdCard type='front' v-model="idcard_1" @change="forntChange" :key="frontKey"></BaseIdCard>
              <BaseIdCard type='back' v-model="idcard_2" @change="backChange" :key="backKey + 'a'"></BaseIdCard>
            </div>
          </div>

          <div class="form-item form-item--50">
            <label class="form-item_label">
              <div>
                <p class="p_label">邀请码</p>
              </div>
            </label>
            <div class="form-item_content">
              <input type="text" class="ipt" placeholder="请输入邀请码" v-model="form.code">
            </div>
          </div>

          <!-- <div class="form-item form-item--agreement">
            <label class="c-radio">
              <input type="checkbox" hidden v-model="agree">
              <div class="c-radio_icon"></div>
            </label>
            申请即表示同意易筑平台<router-link to='/agreement'>《入驻协议》</router-link>
          </div> -->

          <div class="form-item form-item--submit">
            <BaseButton size='large' @click="ok">提交</BaseButton>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import { SettledSubmitApi, SettledReadyApi } from "@/request/api/user";
export default {
  created() {
    SettledReadyApi({ type: 6 }).then(({ status, data }) => {
      if (status == 200) {

        if (data.info) {
          this.form = data.info;
          if (data.info.license) {
            this.licenseArr[0] = data.info.license;
          }
          this.licenKey++;
          if (data.info.id_card_img.length) {
            this.idcard_1 = data.info.id_card_img[0];
          }
          if (data.info.id_card_img.length > 1) {
            this.idcard_2 = data.info.id_card_img[1];
          }
          this.frontKey++;
          this.backKey++;
          this.business = data.info.business_scope;
          this.busiKey++;
          this.service = data.info.service_scope;
          this.serviceKey++;
        }
        data.business_scope.map(item => {
          this.busiArr.push({ value: item, name: item })
        })
        data.service_scope.map(item => {
          this.serviceArr.push({ value: item, name: item })
        })
        this.periodArr = data.period;
        data.area_score.map(item => {
          item.value = item.id;
        })
        this.areaArr = data.area_score;
        this.form.suppliers_phone = data.mobile;
      }


    })
  },
  data() {
    return {
      form: {
        type: 6,
        username: "",
        suppliers_phone: "",
        company_name: "",
        id_card_img: [],
        license: "",
        rate: "",
        business_scope: "",
        service_scope: "",
        period: 90,
        area_scope: 30,
        code: "",
      },
      licenseArr: [],
      licenKey: 0,
      frontKey: 0,
      backKey: 0,
      busiKey: 0,
      serviceKey: 0,
      agree: true,
      idcard_1: null,
      idcard_2: null,
      busiArr: [],
      business: [],
      serviceArr: [],
      service: [],
      periodArr: [],
      areaArr: [],
      time: '',
      area: '',
    };
  },
  methods: {
    serviceChange(e) {
      this.form.service_scope = e.toString();
    },
    busiChange(e) {
      this.form.business_scope = e.toString();
    },
    // 营业执照改变
    licenseChange(arr) {
      this.form.license = arr[0];
      this.licenseArr = arr;
    },
    // 身份证正面
    forntChange(str) {
      this.form.id_card_img[0] = str;
    },
    // 身份证反面
    backChange(str) {
      this.form.id_card_img[1] = str;
    },
    ok() {
      let obj = JSON.parse(JSON.stringify(this.form))
      obj.id_card_img = obj.id_card_img.toString();
      SettledSubmitApi(obj).then(({ status, data, msg }) => {
        if (status == 200) {
          this.$message.success(msg);
          this.$router.push(`/join`);
        }
      })

    },
    handleSubmit() {

    }
  }
};
</script>

<style lang="scss" scoped></style>